import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Box, Button } from "@material-ui/core"
import i18next from "i18next"

import { useAppSelector } from "../../hooks/storeHooks"
import { analytics, LogEventType } from "../../services/analytics"
import { selectChosenLaboratory } from "../../store/medicalExamsPoints/medicalExamsPoints.selectors"
import ButtonLoader from "../common/buttonLoader/ButtonLoader.component"
import { RoutePath } from "../../routes/Routes.types"
import { useActionButtonsStyles } from "./ActionButtons.styles"

interface ActionButtonsProps {
  isLoading?: boolean;
  isInsuranceVerificationEnabled?: boolean;
  actionSource?: string;
  cancelButtonAction?: () => void;
  submitBtnLabel?: string;
  shouldDisable?: boolean;
}

const ActionButtons: FC<ActionButtonsProps> = ({ 
  isLoading,
  actionSource,
  cancelButtonAction,
  submitBtnLabel,
  isInsuranceVerificationEnabled,
  shouldDisable
}) => {
  const { t } = useTranslation()
  const classes = useActionButtonsStyles()
  const chosenLaboratory =  useAppSelector(selectChosenLaboratory)

  const analyticsEventType = () => {
    switch(actionSource) {
      case "OrderMedicinesForm":
        return {
          submit: () => analytics.sendEventWithDefaultParams(LogEventType.DOZ_CONFIRM_ORDER_BUTTON_CLICK),
          cancel: () => analytics.sendEventWithDefaultParams(LogEventType.DOZ_CANCEL_ORDER_BUTTON_CLICK),
        }
      default:
        return {}
    }
  }

  return (
    <Box 
      className={classes.root}
      justifyContent={
        isInsuranceVerificationEnabled
          ? "flex-end" 
          : "space-between"
      }
    >
      <Button
        color="primary"
        type="submit"
        variant="contained"
        className={classes.submitButton}
        startIcon={isLoading && (<ButtonLoader position="prefix"/>)}
        onClick={analyticsEventType()?.submit}
        disabled={shouldDisable ? !chosenLaboratory : false}
      >
        {submitBtnLabel ?? t("orderMedicines:submitButton")}
      </Button>
      <Button
        component={cancelButtonAction ? undefined : Link}
        to={
          cancelButtonAction
            ? undefined
            : `/${i18next.language}${RoutePath.HOMEPAGE}`
        }
        color="secondary"
        variant="outlined"
        onClick={() => {
          cancelButtonAction && cancelButtonAction()
          analyticsEventType()?.cancel
        }}
      >
        {t("orderMedicines:cancelButton")}
      </Button>
    </Box>
  )
}

export default ActionButtons
